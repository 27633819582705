<template>
  <div class="stock-card">
    <search class="mb-4" @inputChange="inputChange" />
    <div class="row">
      <div v-show="!showUpdate" class="col-lg-3">
        <div class="categories">
          <h6 class="mb-3">Category</h6>
          <ul>
            <a
              href="javascript:void(0)"
              :class="{ active: selectedCategory === null }"
              @click="setCurrentCategory(null)"
              >All</a
            >
            <li v-for="(item, index) in categories" :key="index">
              <a
                href="javascript:void(0)"
                :class="{ active: isCategorySelected(item) }"
                @click="setCurrentCategory(item)"
                >{{ item.name }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-9">
        <h6 class="mb-3">Products</h6>
        <vue-good-table
          class="custom-table-style possible-vertical-align"
          :columns="columns"
          :rows="products"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            perPage: perPage,
            setCurrentPage: currentPage,
          }"
          @on-page-change="onPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'name'">
              <div class="product">
                <img
                  v-if="props.formattedRow[props.column.fileUrl]"
                  :src="`${props.row.courier.fileUrl}`"
                  alt=""
                />
                <span>{{ props.formattedRow[props.column.field] }}</span>
              </div>
            </span>

            <table-number-input
              v-else-if="props.column.field == 'stock_update'"
              v-model="props.row.stock_update"
              @input="updateStock(props.row)"
              :tick="tick"
            ></table-number-input>

            <span
              v-else-if="props.column.field == 'priceWithTax'"
              :class="props.column.field"
            >
              {{ props.formattedRow[props.column.field] | price }}
            </span>

            <span
              v-else-if="props.column.field == 'quantity'"
              :class="props.column.field"
            >
              <strong>{{ props.formattedRow[props.column.field] }}</strong>
            </span>

            <span
              v-else-if="props.column.field == 'oci'"
              :class="props.column.field"
            >
              {{ props.row[props.column.field] - props.row.quantity }} ({{ props.formattedRow[props.column.field] }})
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <table-pagination
              v-model="currentPage"
              :total="pages"
              :per-page="perPage"
              :pageChanged="props.pageChanged"
              :perPageChanged="props.perPageChanged"
            />
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import Search from '@/components/Inputs/Search.vue'
import TableNumberInput from '@/components/Inputs/TableNumberInput.vue'
import TablePagination from '@/components/Table/TablePagination.vue'

export default {
  props: {
    stockType: { type: String, default: '' },
    showUpdate: { type: Boolean, default: false },
    car: { type: String, default: '' },
    carSizes: { type: Array, default: () => [] },
    warehouse: { type: String, default: '' },
    stockChange: { type: Array, default: () => [] },
  },
  components: {
    VueGoodTable,
    TableNumberInput,
    TablePagination,
    Search,
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      perPage: 25,
      currentPage: 1,
      pages: 0,
      categories: [],
      selectedCategory: null,
      tick: 0,
      columns: [
        {
          field: 'name',
          sortable: false,
        },
        {
          field: 'priceWithTax',
          type: 'decimal',
          sortable: false,
          tdClass: 'text-right',
        },
        {
          field: 'quantity',
          type: 'number',
          sortable: false,
          tdClass: 'text-right',
          width: '100px',
        },
        {
          field: 'stock_update',
          type: 'number',
          sortable: false,
          width: '130px',
          hidden: true,
        },
      ],
      products: [],
      oci: null,
    }
  },
  watch: {
    stockType() {
      if (this.stockType === 'car') {
        this.columns = [
          {
            field: 'name',
            sortable: false,
          },
          {
            field: 'priceWithTax',
            type: 'decimal',
            sortable: false,
            tdClass: 'text-right',
          },
          {
            field: 'quantity',
            type: 'number',
            sortable: false,
            tdClass: 'text-right',
            width: '100px',
          },
          {
            field: 'oci',
            type: 'number',
            sortable: false,
          },
          {
            field: 'stock_update',
            type: 'number',
            sortable: false,
            width: '130px',
            hidden: true,
          },
        ]
      } else {
        this.columns = [
          {
            field: 'name',
            sortable: false,
          },
          {
            field: 'priceWithTax',
            type: 'decimal',
            sortable: false,
            tdClass: 'text-right',
          },
          {
            field: 'quantity',
            type: 'number',
            sortable: false,
            tdClass: 'text-right',
            width: '100px',
          },
          {
            field: 'stock_update',
            type: 'number',
            sortable: false,
            width: '130px',
            hidden: true,
          },
        ]
      }
    },
    showUpdate() {
      if(this.stockType === 'car') {
        this.$set(this.columns[4], 'hidden', !this.showUpdate)
      } else {
        this.$set(this.columns[3], 'hidden', !this.showUpdate)
      }
    },
    car() {
      this.setStockUpdate()
      this.setCarOci()
    },
    warehouse() {
      this.setStockUpdate()
    },
    stockChange: {
      deep: true,
      handler (data) {
        if(data.length) {
          this.products.forEach(p => {
            const fp = data.find(u => u.id === p.id)
            if (fp) {
              this.updateProduct(p.id, fp.stock_update, fp.name)
            }
          })
        }
      }
    }
  },
  created() {
    this.loadOci()
    this.loadCategories()
    this.loadProducts()
  },
  methods: {
    updateProduct(pid, stockUpdate, name) {
      const index = this.products.findIndex(p => p.id === pid)
      this.$set(this.products[index], 'name', '') // reactivity problem fix
      this.$set(this.products[index], 'name', name)
      this.$set(this.products[index], 'stock_update', stockUpdate)
    },
    loadProducts() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        enabled: true
      }
      if (this.selectedCategory !== null) {
        params['product.category'] = this.selectedCategory
      }
      if (this.search !== '') {
        params.search = this.search
      }
      this.$ProductVariants.getCollection({ params }, 'product_variant_stock_management_list').then(response => {
        this.products = response.data['hydra:member']
        this.pages = response.data['hydra:totalItems']
        this.setStockUpdate()
        this.setCarOci()
      })
    },
    loadCategories() {
      this.$Categories.getCollection({}, 'category_list').then(response => {
        this.categories = response.data['hydra:member']
      })
    },
    loadOci() {
      this.$OptimalCarInventories
        .getCollection({
          params: { pagination: false },
        })
        .then(response => {
          this.oci = response.data['hydra:member']
        })
    },
    setCarOci() {
      if (this.oci) {
        this.products.forEach(product => {
          product.inventories.forEach(inventory => {
            if (inventory.car && inventory.car === this.car) {
              const size = this.carSizes[this.car]
              const oci = this.oci.find(
                o => o.variant === product['@id'] && o.size === size,
              )
              product.oci = oci.quantity
            }
          })
        })
      }
    },
    setCurrentCategory(item) {
      if (item === null) {
        this.selectedCategory = null
      } else {
        this.selectedCategory = item.id
      }
      this.loadProducts()
    },
    isCategorySelected(item) {
      if (
        item &&
        this.selectedCategory !== null &&
        item.id === this.selectedCategory
      ) {
        return true
      }
      return false
    },
    inputChange(value) {
      this.search = value
      this.loadProducts()
    },
    setStockUpdate() {
      // set quantity
      this.products.forEach(product => {
        let inventory = null
        if (this.car && this.car.length) {
          inventory = product.inventories.find(inv => inv.car === this.car)
        }
        if (this.warehouse && this.warehouse.length) {
          inventory = product.inventories.find(
            inv => inv.warehouse === this.warehouse,
          )
        }
        product.quantity = inventory.available || 0
        product.stock_update = 0

        // fix input value when pagiantion change
        const productInStockChange = this.stockChange.find(
          p => p.id === product.id,
        )
        if (productInStockChange) {
          product.stock_update = productInStockChange.stock_update
        }
      })

      // trigger array change for vue
      const newproducts = this.products
      this.products = []
      this.products.push(...newproducts)
      this.tick += 1
    },
    updateStock(product) {
      this.$emit('stockUpdate', product)
    },
    onPageChange() {
      if (this.tick > 0) {
        this.loadProducts()
      }
      this.tick += 1
    },
  },
}
</script>
