<template>
  <div class="admin-orders-listing">
    <title-bar
      :title="title"
      submitBtn
      :submitBtnTitle="buttonText"
      @submitPressed="toggleStockChange"
      :secondaryBtn="showSecondary"
      secondaryBtnTitle="Load the optimal number of pieces in the car"
      @secondaryPressed="setInventoryByOCI"
    />
    <div class="stock-manager">
      <div class="stock-card">
        <b-tabs
          pills
          class="order-listing-tabs"
          v-model="tabIndex"
          @input="loadStock"
        >
          <b-tab
            v-for="(tab, tabIndex) of tabs"
            :key="tabIndex"
            :title="tab.title"
          ></b-tab>
        </b-tabs>
        <stock-listing
          :showUpdate="showChangeCard"
          :car="car"
          :car-sizes="carSizes"
          :warehouse="warehouse"
          :stockChange="stockChange"
          :stock-type="stockType"
          @stockUpdate="addToCart"
          ref="stockList"
        />
      </div>
      <div class="stock-change-card" :class="{ active: showChangeCard }">
        <h6>{{ cartTitle }}</h6>
        <div class="stock-change-card-products">
          <div
            v-for="(product, index) of stockChange"
            :key="index"
            class="stock-change-card-product"
          >
            <div class="stock-change-card-product-info">
              <img
                v-if="product.fileUrl"
                :src="`${product.fileUrl}`"
                alt=""
              />
              <span>{{ product.name }}</span>
            </div>
            <table-number-input
              v-model="product.stock_update"
            ></table-number-input>
            <img
              @click="removeFromStockChange(product)"
              class="stock-change-card-product-delete"
              src="@/assets/images/icons/close.svg"
              alt
            />
          </div>
        </div>
        <b-button class="submit-load" variant="success" v-if="stockChange.length && viaOptimal" @click="confirmSettingInvByOCI">Confirm optimal load</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BTabs } from 'bootstrap-vue'
import TitleBar from '@/components/TitleBar.vue'
import TableNumberInput from '@/components/Inputs/TableNumberInput.vue'
import StockListing from './components/listing.vue'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    TitleBar,
    BTabs,
    TableNumberInput,
    StockListing,
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      tabIndex: 0,
      warehouse: '',
      car: '',
      carSizes: [],
      stockChange: [],
      tabs: [],
      title: 'Stock',
      buttonText: 'Income items',
      cartTitle: 'Goods for receipt',
      showChangeCard: false,
      showSecondary: false,
      cars: [],
      stockType: null,
      viaOptimal: false
    }
  },
  created() {
    this.loadTabs()
  },
  methods: {
    loadTabs() {
      this.$Warehouses.getCollection({}, 'warehouse_list').then(locResponse => {
        locResponse.data['hydra:member'].forEach(element => {
          this.tabs.push({
            title: element.name,
            warehouse: element['@id'],
            car: '',
            type: 'warehouse'
          })
        })
        this.$Cars.getCollection({}, 'car_list').then(carResponse => {
          this.cars = carResponse.data['hydra:member']
          carResponse.data['hydra:member'].forEach(element => {
            this.tabs.push({
              title: element.name,
              warehouse: '',
              car: element['@id'],
              type: 'car'
            })
            this.carSizes[element['@id']] = element.size
          })
        })
      })
    },
    loadStock() {
      this.viaOptimal = false
      this.showSecondary = false
      this.showChangeCard = false
      this.updateButtonText()

      if (this.tabIndex >= 0) {
        this.stockChange = []
        this.car = this.tabs[this.tabIndex].car
        this.warehouse = this.tabs[this.tabIndex].warehouse
        this.stockType = this.tabs[this.tabIndex].type
      }
    },
    addToCart(newProduct) {
      let updated = false

      if (newProduct.stock_update === 0) {
        this.stockChange = this.stockChange.filter(
          p => newProduct.id !== p.id,
        )
        return
      }

      this.stockChange.forEach(product => {
        if (product.id === newProduct.id) {
          product.stock_update = newProduct.stock_update
          updated = true
        }
      })

      if (!updated) {
        this.stockChange.push(newProduct)
      }
    },
    toggleStockChange() {
      this.showChangeCard = !this.showChangeCard
      this.updateButtonText()
      this.processStockChange()
    },
    processStockChange() {
      if (this.stockChange.length) {
        const promises = []
        this.stockChange.forEach(product => {
          let inventory = null
          let isOutcome = false
          if (this.car && this.car.length) {
            inventory = product.inventories.find(inv => inv.car === this.car)
            isOutcome = true
            this.confirmSettingInvByOCI()
            return
          }
          if (this.warehouse && this.warehouse.length) {
            inventory = product.inventories.find(inv => inv.warehouse === this.warehouse)
          }
          const available = (isOutcome) ? inventory.available - product.stock_update : inventory.available + product.stock_update
          promises.push(this.updateInventory(inventory.id, available))
        })

        Promise.all(promises)
        .then(results => {
          if (results.length) {
            this.$helper.showToast(this.$toast, Toast, 'Changes saved', '', 'success')
            this.stockChange = []
            this.$refs.stockList.loadProducts()
          }
        })
      }
    },
    updateInventory(id, available) {
      const body = {
        available,
      }
      return this.$Inventories
        .updateResource({ id, body })
        .then(() => 'success')
        .catch(error => {
          if (error.response) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              error.response.data['hydra:title'],
              error.response.data['hydra:description'],
              'danger',
            )
          }
          return 'failed'
        })
    },
    removeFromStockChange(product) {
      product.stock_update = 0
      this.stockChange = this.stockChange.filter(p => product.id !== p.id)
    },
    updateButtonText() {
      if (this.showChangeCard) {
        if (this.tabIndex === 0) {
          this.buttonText = 'Confirm income'
          this.title = 'Income items'
          this.cartTitle = 'Goods for receipt'
        } else {
          this.buttonText = 'Confirm income from warehouse'
          this.title = 'Income from warehouse'
          this.cartTitle = 'Goods for issue'
          this.showSecondary = true
        }
      }

      if (!this.showChangeCard) {
        this.buttonText = 'Income items'
        this.title = 'Stock'
        this.showSecondary = false
      }
    },
    async setInventoryByOCI() {
      const stockChange = []
      const car = this.cars.find(c => c['@id'] === this.car)
      const ociResponse = await this.$OptimalCarInventories
          .getCollection({
            params: { pagination: false, size: car.size },
          }, 'optimal_car_inventories_list')

      const inventoryResponse = await this.$Inventories
          .getCollection({
            params: { pagination: false, car: car['@id'] },
          }, 'inventory_list')

      inventoryResponse.data['hydra:member'].forEach(inventory => {
        const oci = ociResponse.data['hydra:member'].find(o => o.variant === inventory.variant)
        if(oci) {
          if(inventory.available !== oci.quantity) {
            const pp = inventory.variant.split('/')
            stockChange.push({
              productVariantId: parseInt(pp[pp.length - 1], 10),
              quantity: oci.quantity - inventory.available,
            })
          }
        }
      })

      const params = { pagination: false, id: stockChange.map(p => p.productVariantId )}
      const products = await this.$ProductVariants.getCollection({ params }, 'product_variant_list')
      this.stockChange = []
      // eslint-disable-next-line no-restricted-syntax
      for (const p of products.data['hydra:member']) {
        const toUpdate = stockChange.find(pu => pu.productVariantId === p.id)
        p.stock_update = toUpdate.quantity
        this.stockChange.push(p)
      }
      this.viaOptimal = true
    },
    confirmSettingInvByOCI() {
      // TODO - warehouse natvrdo
      const warehouse = this.tabs.find(i => i.warehouse)
      if (this.stockChange.length) {
        const body = {
          warehouse: warehouse.warehouse,
          car: this.car,
          products: this.stockChange.map(p => ({ productVariantId: p.id, quantity: p.stock_update})),
        }

        this.$AdjustInventories
          .createResource({ body })
          .then(() => {
            this.$helper.showToast(this.$toast, Toast, 'Stock items loaded ', '', 'success')
            this.$refs.stockList.loadProducts()
            this.stockChange = []
            this.showChangeCard = !this.showChangeCard
            this.updateButtonText()
            this.viaOptimal = false
          })
          .catch(error => {
            if (error.response) {
              this.$helper.showToast(
                this.$toast,
                Toast,
                error.response.data['hydra:title'],
                error.response.data['hydra:description'],
                'danger',
              )
            }
          })
      } else {
        this.$helper.showToast(
          this.$toast,
          Toast,
          'Car is already optimally loaded',
          '',
          'success',
        )
      }
    }
  },
}
</script>
