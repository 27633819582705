<template>
  <div class="custom-table-pagination-wrap">
    <v-select
      v-if="enablePerPage"
      v-model="toShow"
      :options="options"
      class="custom-select"
      :clearable="false"
      :searchable="false"
    >
    </v-select>
    <b-pagination
      class="custom-table-pagination"
      v-model="current"
      :total-rows="total"
      :per-page="perPage"
    ></b-pagination>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'

export default {
  name: 'TablePagination',
  components: {
    BPagination,
  },
  data() {
    return {
      current: this.value,
      pages: 1,
      toShow: this.perPage,
      options: this.perPageOptions
    }
  },
  props: {
    value: { type: [Number, String], default: 1 },
    total: { type: Number, default: 0 },
    perPage: { type: [Number, String], default: 10 },
    pageChanged: { type: Function },
    perPageChanged: { type: Function },
    enablePerPage: { type: Boolean, default: false },
    perPageOptions: {
      type: Array,
      default: () => [
        {
          label: 5,
          id: 5,
        },
        {
          label: 10,
          id: 10,
        },
        {
          label: 20,
          id: 20,
        },
        {
          label: 'All',
          id: 'All',
        },
      ],
    },
  },
  watch: {
    current(item) {
      this.$emit('input', item)
      this.setPage(item)
    },
    toShow(show) {
      this.$emit('perPageChange', show.id)
      this.setPerPage(show)
    }
  },
  created() {
    this.calPageCount()
  },
  methods: {
    setPage(page) {
      this.current = page
      this.pageChanged({ current: this.current })
    },
    previous() {
      if (this.current > 0) this.setPage(this.current - 1)
    },
    next() {
      if (this.current < this.pages) this.setPage(this.current + 1)
    },
    setPerPage(perPage) {
      if(perPage.id === 'All') {
        this.toShow = {
          label: 'All',
          id: this.total,
        }
      } else {
        this.toShow = perPage
      }
      this.calPageCount()
      this.perPageChanged({ currentPerPage: this.toShow })
    },
    calPageCount() {
      if (this.total > this.toShow) {
        this.pages = Math.round(this.total / this.toShow)
      } else {
        this.pages = 1
        this.setPage(1)
      }
    },
  },
}
</script>